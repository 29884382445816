var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"report-progress",attrs:{"fluid":""}},[_c('VRow',{staticClass:"align-center"},[_c('VCol',{attrs:{"cols":"9"}},[_c('h1',{staticClass:"mb-2 tt-text-headline-1"},[_vm._v(" Отчет по активности ")]),_c('div',{staticClass:"tt-black--text text--lighten-2 mb-4"},[_vm._v(" Показывает, когда и сколько заданий выполнили сотрудники ")])])],1),_c('VRow',{staticClass:"mb-4 align-center"},[_c('VCol',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('TTSelect',{ref:"locationDropDown",staticClass:"tt-select-attach",attrs:{"value":_vm.currentLocationId,"items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","large":"","label":"Локация","menu-props":{ auto : true },"attach":"","placeholder":"Выбор локации"},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}])})],1),_c('VCol',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('TTSelect',{attrs:{"items":_vm.stateType,"item-text":"name","large":"","label":"Статус","placeholder":"Выбор статуса","data-test":"choose-status"},on:{"change":_vm.handleStatus},model:{value:(_vm.filter.state),callback:function ($$v) {_vm.$set(_vm.filter, "state", $$v)},expression:"filter.state"}})],1),_c('VCol',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('TTSelect',{attrs:{"items":_vm.periodList,"large":"","label":"Период","placeholder":"Выбор периода","data-test":"choose-status"},on:{"change":_vm.handlePeriodChange},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1),_c('VCol',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('TTTextField',{attrs:{"placeholder":"Поиск по ФИО","append-icon":"fal fa-search","large":"","label":"Поиск","data-test":"input-search"},on:{"input":_vm.handleStaffTextSearch},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1),(_vm.currentLocationId)?_c('VRow',[_c('VCol',[_c('TTDataTable',{attrs:{"items":_vm.staffList,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","loading":!_vm.isLoaded},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"cursor--pointer",on:{"click":function($event){return _vm.$router.push(("/staff/" + (item.id)))}}},[_vm._v(" "+_vm._s(item.last_name)+" "+_vm._s(item.first_name)+" "),_c('div',{staticClass:"tt-black--text text--lighten-2"},[_c('TTTooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"tt-black--text text--lighten-2 ellipsis-two-lines ellipsis"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentLocationName)),(item.position)?_c('span',[_vm._v(", "+_vm._s(item.position))]):_vm._e()])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s(_vm.currentLocationName)),(item.position)?_c('span',[_vm._v(", "+_vm._s(item.position))]):_vm._e()])])],1)]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.calcBonus(item.id))+" ")])]),_c('td',[_c('ReportStaffCell',{key:item.id,staticClass:"transition--width",attrs:{"values":_vm.visitsMap[item.id] || [],"date-start":_vm.dateStart,"date-end":_vm.dateEnd,"period":_vm.period,"loaded":_vm.isLoaded,"started":item.started}})],1)])]}}],null,false,1819842786)})],1)],1):_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-3 tt-text-headline-1"},[_vm._v(" Не задана локация ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите локацию для получения данных. ")])])],1),(_vm.isLoaded && !_vm.fullLoad)?_c('VRow',[_c('VCol',[_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.getMore),expression:"getMore",modifiers:{"quiet":true}}]})])],1):_vm._e(),(!_vm.isLoaded && !_vm.fullLoad)?_c('VRow',[_c('VCol',{attrs:{"align":"center"}},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }