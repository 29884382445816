<template>
  <VMenu
    v-if="entity.fullDate"
    v-model="menu"
    open-on-hover
    top
    offset-y
    content-class="v-menu-shadow"
    max-width="150"
    min-width="150"
  >
    <template #activator="{ on, attrs }">
      <div
        class="report-staff-bar cursor--pointer"
        :class="{'report-staff-bar_disabled' : entity.task_count === -1}"
        v-bind="attrs"
        v-on="on"
      >
        <div
          class="report-staff-bar__bar"
          :style="`height: ${height};`"
        />
      </div>
    </template>
    <div class="report-staff-bar__card pa-4 v-menu-card">
      <div class="report-staff-bar__date mb-1">
        {{ entity.fullDate }}
      </div>
      <template v-if="entity.task_count !== -1">
        <div>{{ entity.bonus }} баллов</div>
        <div>
          {{ entity.task_count }}
          {{ pluralize(entity.task_count, 'задание', 'задания', 'заданий') }}
        </div>
      </template>
      <div v-else>
        Нет активности
      </div>
    </div>
  </VMenu>
  <div
    v-else
    class="report-staff-bar"
  />
</template>

<script>
import { pluralize } from '@/utils';

export default {
  name: 'ReportStaffBar',
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    height() {
      if (this.entity.task_count >= 5) return '24px';
      if (this.entity.task_count >= 1) return '12px';
      return '2px';
    },
  },
  methods: {
    pluralize,
  },
};
</script>

<style>
  .report-staff-bar {
    width: 100%;
    height: 24px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
  .report-staff-bar .report-staff-bar__bar {
    width: 100%;
    background: linear-gradient(180deg, #00B8FF 0%, #601EF3 100%);
  }
  .report-staff-bar.report-staff-bar_disabled .report-staff-bar__bar {
    background: #D8D9DA;
  }
  .report-staff-bar__card {
    background-color: white;
    box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
    border-radius: 8px;
  }
  .report-staff-bar__date {
    font-size: 12px;
    color: #8F9295;
  }

</style>
