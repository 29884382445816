<template>
  <div class="report-staff-cell">
    <div
      class="d-flex flex-nowrap justify-space-between transition--width"
      :style="`margin-right: -${barPadding}`"
    >
      <div
        v-for="entity in entityList"
        :key="entity.date"
        :style="`min-width: ${barWidth}; padding-right: ${barPadding};`"
        class="transition--width w-100"
      >
        <ReportStaffBar
          :entity="entity"
          class="transition--width"
        />
      </div>
    </div>
    <div class="d-flex flex-nowrap justify-space-between mt-2">
      <div
        v-for="date in datesToShow"
        :key="date"
        class="tt-black--text text--lighten-2"
      >
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ReportStaffBar from '@/components/reportStaff/ReportStaffBar.vue';

const defaultValue = {
  bonus: 0,
  task_count: -1,
};

const periodTypes = {
  twoWeeks: 'twoWeeks',
  month: 'month',
  threeMonth: 'threeMonth',
};

export default {
  name: 'ReportStaffCell',
  components: { ReportStaffBar },
  props: {
    values: {
      type: Array,
      required: true,
    },
    dateStart: {
      type: Object,
      required: true,
    },
    dateEnd: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    loaded: {
      type: Boolean,
      required: true,
    },
    started: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valuesMap: {},
      entityList: [],
      defaultValue,
    };
  },
  computed: {
    drawer() {
      return this.$store.getters.drawer;
    },
    barWidth() {
      switch (this.period) {
        case periodTypes.twoWeeks:
          return this.drawer ? '20px' : '36px';
        case periodTypes.month:
          return this.drawer ? '12px' : '16px';
        case periodTypes.threeMonth:
        default:
          return this.drawer ? '2px' : '4px';
      }
    },
    barPadding() {
      switch (this.period) {
        case periodTypes.twoWeeks:
          return '12px';
        case periodTypes.month:
          return '8px';
        case periodTypes.threeMonth:
        default:
          return '3px';
      }
    },
    datesToShow() {
      const totalDates = this.entityList.length;
      const divider = Math.ceil(totalDates / 7);
      const datesArr = this.entityList
        .filter((item, i) => !(i % divider))
        .map((item) => item.date);
      datesArr.pop();
      datesArr.push(this.entityList[totalDates - 1].date);
      return datesArr;
    },
  },
  watch: {
    loaded: {
      handler(val) {
        if (val) {
          this.valuesMap = {};
          this.entityList = [];
          this.handleValues();
          this.prepareDates();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleValues() {
      if (!this.values.length) return;
      this.values.forEach((value) => {
        this.valuesMap[value.date] = value;
      });
    },
    prepareDates() {
      const started = this.started ? dayjs(this.started) : null;
      let hasVisit = false;
      let dateIterator = this.dateStart.clone();
      do {
        const date = dateIterator.format('YYYY-MM-DD');
        if (this.valuesMap[date]) {
          hasVisit = true;
          this.entityList.push({
            ...this.valuesMap[date],
            date: dateIterator.format('DD.MM'),
            fullDate: dateIterator.format('DD.MM.YYYY (dd)'),
          });
        } else if ((started && started.isSameOrBefore(dateIterator)) || hasVisit) {
          this.entityList.push({
            ...this.defaultValue,
            date: dateIterator.format('DD.MM'),
            fullDate: dateIterator.format('DD.MM.YYYY (dd)'),
          });
        } else {
          this.entityList.push({
            date: dateIterator.format('DD.MM'),
          });
        }
        dateIterator = dateIterator.add(1, 'day');
      } while (dateIterator.isSameOrBefore(this.dateEnd));
    },
  },
};
</script>

<style>
.report-staff-cell {
  min-width: 446px;
}
.transition--width {
  transition: width .2s ease;
  width: 100%;
}
</style>
