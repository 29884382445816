<template>
  <VContainer
    fluid
    class="report-progress"
  >
    <VRow class="align-center">
      <VCol cols="9">
        <h1 class="mb-2 tt-text-headline-1">
          Отчет по активности
        </h1>
        <div class="tt-black--text text--lighten-2 mb-4">
          Показывает, когда и сколько заданий выполнили сотрудники
        </div>
      </VCol>
    </VRow>
    <VRow class="mb-4 align-center">
      <VCol
        cols="3"
        class="py-0"
      >
        <TTSelect
          ref="locationDropDown"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          return-object
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          placeholder="Выбор локации"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
      <VCol
        cols="3"
        class="py-0"
      >
        <TTSelect
          v-model="filter.state"
          :items="stateType"
          item-text="name"
          large
          label="Статус"
          placeholder="Выбор статуса"
          data-test="choose-status"
          @change="handleStatus"
        />
      </VCol>
      <VCol
        cols="3"
        class="py-0"
      >
        <TTSelect
          v-model="period"
          :items="periodList"
          large
          label="Период"
          placeholder="Выбор периода"
          data-test="choose-status"
          @change="handlePeriodChange"
        />
      </VCol>
      <VCol
        cols="3"
        class="py-0"
      >
        <TTTextField
          v-model="filter.name"
          placeholder="Поиск по ФИО"
          append-icon="fal fa-search"
          large
          label="Поиск"
          data-test="input-search"
          @input="handleStaffTextSearch"
        />
      </VCol>
    </VRow>
    <VRow v-if="currentLocationId">
      <VCol>
        <TTDataTable
          :items="staffList"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :loading="!isLoaded"
        >
          <template #item="{item}">
            <tr>
              <td
                class="cursor--pointer"
                @click="$router.push(`/staff/${item.id}`)"
              >
                {{ item.last_name }} {{ item.first_name }}
                <div class="tt-black--text text--lighten-2">
                  <TTTooltip
                    bottom
                    max-width="300"
                  >
                    <template #activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        class="tt-black--text text--lighten-2 ellipsis-two-lines ellipsis"
                        v-on="on"
                      >
                        {{ currentLocationName }}<span v-if="item.position">, {{ item.position }}</span>
                      </p>
                    </template>
                    <p>
                      {{ currentLocationName }}<span v-if="item.position">, {{ item.position }}</span>
                    </p>
                  </TTTooltip>
                </div>
              </td>

              <td>
                <div>
                  {{ calcBonus(item.id) }}
                </div>
              </td>
              <td>
                <ReportStaffCell
                  :key="item.id"
                  :values="visitsMap[item.id] || []"
                  :date-start="dateStart"
                  :date-end="dateEnd"
                  :period="period"
                  :loaded="isLoaded"
                  :started="item.started"
                  class="transition--width"
                />
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h1 class="mb-3 tt-text-headline-1">
          Не задана локация
        </h1>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>

    <VRow v-if="isLoaded && !fullLoad">
      <VCol>
        <div v-intersect.quiet="getMore" />
      </VCol>
    </VRow>
    <VRow v-if="!isLoaded && !fullLoad">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { staffStates } from '@/constants';
import { pageLocationToken } from '@/services';
import { formatDate } from '@/utils';

import ReportStaffCell from '@/components/reportStaff/ReportStaffCell.vue';
import { API_REPORT_STAFF } from '@/services/backend/snami/constants';
import { downloadCSVLink } from '@/utils/CSV';
import localStorageInterface from '@/services/localStorage/_utils';
import TextTemplate from '@/components/shared/TextTemplate.vue';

const pageReportStaffStatusToken = localStorageInterface('snami_page_report_staff_status');
const pageReportStaffPeriodToken = localStorageInterface('snami_page_report_staff_period');
const pageReportStaffNameToken = localStorageInterface('snami_page_report_staff_name');

const periodTypes = {
  twoWeeks: 'twoWeeks',
  month: 'month',
  threeMonth: 'threeMonth',
};

export default {
  name: 'ReportStaff',
  components: { TextTemplate, ReportStaffCell },
  data() {
    return {
      filter: {
        state: pageReportStaffStatusToken.get() || staffStates.ADAPTING,
        name: pageReportStaffNameToken.get() || '',
      },
      total: 0,

      dateStart: null,
      dateEnd: null,
      period: pageReportStaffPeriodToken.get() || periodTypes.twoWeeks,
      periodList: [
        { text: '14 дней', value: periodTypes.twoWeeks },
        { text: '30 дней', value: periodTypes.month },
        { text: '90 дней', value: periodTypes.threeMonth },
      ],
      stateType: [
        {
          value: staffStates.ACTIVE,
          name: 'Действующие',
        },
        {
          value: staffStates.INVITED,
          name: 'Приглашенные',
        },
        {
          value: staffStates.ADAPTING,
          name: 'Адаптирующиеся',
        },
        {
          value: staffStates.ADAPTED,
          name: 'Адаптированные',
        },
        {
          value: staffStates.WITHOUT_ADAPTATION,
          name: 'Без адаптации',
        },
        {
          value: staffStates.FIRED,
          name: 'Уволенные',
        },
      ],
      isLoaded: false,
      locations: [],
      taskMap: {},
      staffList: [],
      visitsMap: {},
      currentLocationId: null,
      page: {},
    };
  },
  computed: {
    totalItems() {
      return this.page.total_item || 0;
    },
    fullLoad() {
      return this.staffList.length >= this.totalItems;
    },
    currentPage() {
      return this.page.n || 0;
    },
    drawer() {
      return this.$store.getters.drawer;
    },
    periodText() {
      const periodObj = this.periodList.find((item) => item.value === this.period);
      return periodObj.text;
    },
    headers() {
      const dateStart = this.formatDate(this.dateStart);
      const dateEnd = this.formatDate(this.dateEnd);
      const headers = [
        {
          sortable: false,
          text: 'Сотрудник',
          align: 'left',
          value: 'staff',
          width: this.drawer ? 260 : 420,
        },
        {
          sortable: false,
          text: 'Баллы',
          align: 'left',
          value: 'bonus',
        },
        {
          sortable: false,
          text: `${dateStart} - ${dateEnd} (${this.periodText})`,
          align: 'left',
          value: 'staffData',
        },
      ];
      return headers;
    },
    currentLocationName() {
      if (this.currentLocationId && this.locations.length) {
        const locationIdx = this.locations.findIndex((item) => item.id === this.currentLocationId);
        if (locationIdx !== -1) {
          return this.locations[locationIdx].name;
        }
      }
      return '';
    },
  },
  created() {
    this.setDates();
    this.debouncedGetData = debounce(this.handleFilter, 500);
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = loc;
      this.getData();
    }
    this.$repositories.location.list({}).then((r) => {
      const { data } = r.data;
      this.locations = data;
    }).catch((e) => console.warn(e));
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.$children[0].isMenuActive = true;
    }
    this.$root.$on('downloadReportStaff', this.downloadCSV);
  },
  beforeDestroy() {
    this.$root.$off('downloadReportStaff', this.downloadCSV);
  },
  methods: {
    formatDate,
    downloadCSV() {
      const link = API_REPORT_STAFF(this.currentLocationId);
      downloadCSVLink(link);
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.handleFilter();
    },
    setDates() {
      this.dateEnd = dayjs();
      // eslint-disable-next-line default-case
      switch (this.period) {
        case periodTypes.twoWeeks:
          this.dateStart = this.dateEnd.subtract('13', 'day');
          break;
        case periodTypes.month:
          this.dateStart = this.dateEnd.subtract('29', 'day');
          break;
        case periodTypes.threeMonth:
          this.dateStart = this.dateEnd.subtract('89', 'day');
          break;
      }
    },
    handlePeriodChange() {
      pageReportStaffPeriodToken.set(this.period);
      this.setDates();
      this.getData();
    },
    handleStatus() {
      pageReportStaffStatusToken.set(this.filter.state);
      this.handleFilter();
    },
    handleStaffTextSearch() {
      pageReportStaffNameToken.set(this.filter.name);
      this.debouncedGetData();
    },
    handleFilter() {
      this.page = {};
      this.getData();
    },
    async getData() {
      this.isLoaded = false;
      this.staffList = [];
      this.visitsMap = {};
      this.taskMap = {};

      try {
        const { data: { data: report, page } } = await this.$repositories.report.getReportStaff({
          data: {
            ...this.filter,
            location_id: this.currentLocationId,
            end_at: this.dateEnd.format('YYYY-MM-DD'),
            start_at: this.dateStart.format('YYYY-MM-DD'),
            page: this.currentPage,
          },
        });
        this.page = page;
        this.staffList = report.staff;
        report.tasks.forEach((task) => {
          this.taskMap[task.id] = task;
        });
        report.visits.forEach((item) => {
          this.visitsMap[item.staff_id] = item.values;
        });
        if (page) {
          this.total = page.total_item;
        } else {
          this.total = 0;
        }
      } catch (e) {
        console.warn(e);
      }
      this.isLoaded = true;
    },
    async getMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.isLoaded = false;

        try {
          const { data: { data: report, page } } = await this.$repositories.report.getReportStaff({
            data: {
              ...this.filter,
              location_id: this.currentLocationId,
              end_at: this.dateEnd.format('YYYY-MM-DD'),
              start_at: this.dateStart.format('YYYY-MM-DD'),
              page: this.currentPage + 1,
            },
          });
          this.page = page;
          this.staffList = [...this.staffList, ...report.staff];
          report.tasks.forEach((task) => {
            this.taskMap[task.id] = task;
          });
          report.visits.forEach((item) => {
            this.visitsMap[item.staff_id] = item.values;
          });
        } catch (e) {
          console.warn(e);
        }
        this.isLoaded = true;
      }
    },
    calcBonus(staffId) {
      if (!this.visitsMap[staffId]) return 0;
      return this.visitsMap[staffId].reduce((sum, item) => sum + item.bonus, 0);
    },
  },
};
</script>

  <style>
  .transition--width {
    transition: width .2s ease;
    width: 100%;
  }
  </style>
